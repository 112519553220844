import { useAppSelector } from "@/lib/hooks";
import { Loader } from "@/utils/Loader";
import { Flex } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
};

export default function SignInLayout({ children }: Props) {
  const accessToken = useAppSelector((state) => state.user.accessToken);

  if (accessToken !== "") {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        minH={"100dvh"}
        maxW={"100%"}
        h={"100vh"}
      >
        <Loader />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="flex-start"
        minH={"100dvh"}
        maxW={"100%"}
      >
        {children}
      </Flex>
    </>
  );
}
